import { IDictionary } from '@scb-lib/models';
export const EMAIL_REGEXP: RegExp =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

export function arrayToObj(arr: any[], key?: string): IDictionary<any> {
    return arr.reduce((cum, each) => {
        if (key !== undefined) {
            cum[each[key]] = each;
        } else {
            cum[each] = true;
        }
        return cum;
    }, {});
}

export const getValue = (obj: any, path: string | string[], def: any = null) => {
    if (!obj || !path) {
        return def;
    }
    const pathArr: string[] = Array.isArray(path) ? path : path.split('.');
    return pathArr.reduce((o, key) => {
        try {
            const v = o[key];
            return v;
        } catch (e) {
            return def;
        }
    }, obj);
};

export function arraySortBy(fields: string[]) {
    return (a: any, b: any) =>
        fields
            .map((field) => {
                let dir = 1;
                let f: string;
                if (field[0] === '-') {
                    dir = -1;
                    f = field.substring(1);
                } else {
                    f = field;
                }

                let A: any;
                let B: any;
                A = getValue(a, f);
                if (typeof A === 'string') {
                    A = A.toLowerCase();
                }
                B = getValue(b, f);
                if (typeof B === 'string') {
                    B = B.toLowerCase();
                }

                return A > B ? dir : A < B ? -dir : 0;
            })
            .reduce((p, n) => (p ? p : n), 0);
}
