export type IPageMetaData = Partial<{    setByComponent: boolean;
    addSuffixAppNameToTitle: boolean;
    title: string;
    description: string;
    tags: string[];
    keywords: string;
    url: string;
    image: string;
    image_alt: string;
    image_width: string;
    image_height: string;
    type: string;
    locale: string;
    locale_alternate: string;
    site_name: string;
    twitter_site: string;
    canonicalUrl: string; // canonicalUrl: must be set with leading slash '/' and without trailing slash '/' eg. '/activities',
    twitter_card: 'summary' | 'summary_large_image' | 'app' | 'player';
}>;

// usefull to generate the title and meta tags and have autocomplete in routes files
export const buildPageMetaData = (data: IPageMetaData): IPageMetaData => {
    return { ...data };
};

export const PAGE_META_DATA_DEFAULT: IPageMetaData = {
    title: 'Say CHEESE Bistrot',
    description:
        'Say CHEESE Bistrot, vedi il menù, gli orari, prenota un tavolo, vedi le nostre attività, iscriviti alla newsletter e gestisci le modalità di contatto',
    site_name: 'Say CHEESE Bistrot',
    type: 'website',
    image: 'https://firebasestorage.googleapis.com/v0/b/scb-website.appspot.com/o/scb_brand_logo%2Flogo_scb_1200x628.jpg?alt=media&token=ae63872b-b3bf-40ca-b3c4-82e02af293fb',
    image_alt: 'Say CHEESE Bistrot logo',
    image_width: '1200',
    image_height: '628',
    locale: 'it_IT',
    locale_alternate: 'en_US',
    url: 'https://saycheesebistrot.com',
    tags: [
        'say',
        'cheese',
        'bistrot',
        'bistro',
        'bistrò',
        'formaggio',
        'newsletter',
        'prenota',
        'book',
        'menu',
        'menù',
        'ristorante',
        'restaurant',
        'vino',
        'wine',
        'bottega',
        'negozio',
        'shop',
        'enoteca',
        'cantina',
        'cellar',
    ],
    canonicalUrl: '/',
    twitter_card: 'summary_large_image',
};

export const TEST_PAGE_META_DATA_OVERRIDES: IPageMetaData = {
    title: 'Say CHEESE Bistrot - TEST',
    image: 'https://firebasestorage.googleapis.com/v0/b/scb-website.appspot.com/o/scb_brand_logo%2Flogo_scb_1200x628.jpg?alt=media&token=ae63872b-b3bf-40ca-b3c4-82e02af293fb',
    url: 'https://testweb.saycheesebistrot.com',
};
